import { ElMessage } from 'element-plus';
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import md5 from 'md5'
import innercopy from 'copy-to-clipboard'
import trimEnd from 'lodash/trimEnd'

export const pascalCase = (str: string) => {
  return upperFirst(camelCase(str))
}

function copy(text: string) {
  innercopy(text)
  ElMessage.success('复制成功')
}

function getFileNameFromUrl(url: string) {
  const matches = url.match(/([^\\/]+)$/)
  if (!matches) {
    return ''
  } else {
    return matches[1]
  }
}

function getFilenameAndPathFromUrl(url: string) {
  const filename = getFileNameFromUrl(url)
  const path = trimEnd(url.slice(0, url.lastIndexOf(filename)), '/') || '.'
  return {
    filename,
    path
  }
}

export { md5, copy, getFileNameFromUrl, getFilenameAndPathFromUrl  }
