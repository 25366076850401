import o from "ace-builds";
import "ace-builds/src-noconflict/ext-language_tools";
import { defineComponent as r, h as a, markRaw as n, capitalize as l } from "vue";
import c from "resize-observer-polyfill";
import "ace-builds/webpack-resolver";
const _ = [
  "abap",
  "abc",
  "actionscript",
  "ada",
  "alda",
  "apache_conf",
  "apex",
  "applescript",
  "aql",
  "asciidoc",
  "asl",
  "assembly_x86",
  "autohotkey",
  "batchfile",
  "c9search",
  "c_cpp",
  "cirru",
  "clojure",
  "cobol",
  "coffee",
  "coldfusion",
  "crystal",
  "csharp",
  "csound_document",
  "csound_orchestra",
  "csound_score",
  "csp",
  "css",
  "curly",
  "d",
  "dart",
  "diff",
  "django",
  "dockerfile",
  "dot",
  "drools",
  "edifact",
  "eiffel",
  "ejs",
  "elixir",
  "elm",
  "erlang",
  "forth",
  "fortran",
  "fsharp",
  "fsl",
  "ftl",
  "gcode",
  "gherkin",
  "gitignore",
  "glsl",
  "gobstones",
  "golang",
  "graphqlschema",
  "groovy",
  "haml",
  "handlebars",
  "haskell",
  "haskell_cabal",
  "haxe",
  "hjson",
  "html",
  "html_elixir",
  "html_ruby",
  "ini",
  "io",
  "ion",
  "jack",
  "jade",
  "java",
  "javascript",
  "json",
  "json5",
  "jsoniq",
  "jsp",
  "jssm",
  "jsx",
  "julia",
  "kotlin",
  "latex",
  "latte",
  "less",
  "liquid",
  "lisp",
  "livescript",
  "logiql",
  "logtalk",
  "lsl",
  "lua",
  "luapage",
  "lucene",
  "makefile",
  "markdown",
  "mask",
  "matlab",
  "maze",
  "mediawiki",
  "mel",
  "mips",
  "mixal",
  "mushcode",
  "mysql",
  "nginx",
  "nim",
  "nix",
  "nsis",
  "nunjucks",
  "objectivec",
  "ocaml",
  "partiql",
  "pascal",
  "perl",
  "pgsql",
  "php",
  "php_laravel_blade",
  "pig",
  "plain_text",
  "powershell",
  "praat",
  "prisma",
  "prolog",
  "properties",
  "protobuf",
  "puppet",
  "python",
  "qml",
  "r",
  "raku",
  "razor",
  "rdoc",
  "red",
  "redshift",
  "rhtml",
  "rst",
  "ruby",
  "rust",
  "sac",
  "sass",
  "scad",
  "scala",
  "scheme",
  "scrypt",
  "scss",
  "sh",
  "sjs",
  "slim",
  "smarty",
  "smithy",
  "snippets",
  "soy_template",
  "space",
  "sparql",
  "sql",
  "sqlserver",
  "stylus",
  "svg",
  "swift",
  "tcl",
  "terraform",
  "tex",
  "text",
  "textile",
  "toml",
  "tsx",
  "turtle",
  "twig",
  "typescript",
  "vala",
  "vbscript",
  "velocity",
  "verilog",
  "vhdl",
  "visualforce",
  "wollok",
  "xml",
  "xquery",
  "yaml",
  "zeek"
], g = [
  "ambiance",
  "chaos",
  "chrome",
  "cloud9_day",
  "cloud9_night",
  "cloud9_night_low_color",
  "clouds",
  "clouds_midnight",
  "cobalt",
  "crimson_editor",
  "dawn",
  "dracula",
  "dreamweaver",
  "eclipse",
  "github",
  "gob",
  "gruvbox",
  "idle_fingers",
  "iplastic",
  "katzenmilch",
  "kr_theme",
  "kuroir",
  "merbivore",
  "merbivore_soft",
  "mono_industrial",
  "monokai",
  "nord_dark",
  "one_dark",
  "pastel_on_dark",
  "solarized_dark",
  "solarized_light",
  "sqlserver",
  "terminal",
  "textmate",
  "tomorrow",
  "tomorrow_night",
  "tomorrow_night_blue",
  "tomorrow_night_bright",
  "tomorrow_night_eighties",
  "twilight",
  "vibrant_ink",
  "xcode"
], i = [
  "blur",
  "input",
  "change",
  "changeSelectionStyle",
  "changeSession",
  "copy",
  "focus",
  "paste"
], f = r({
  name: "vue3-ace-editor",
  props: {
    value: {
      type: String,
      required: !0
    },
    lang: {
      type: String,
      default: "text"
    },
    theme: {
      type: String,
      default: "chrome"
    },
    options: Object,
    placeholder: String,
    readonly: Boolean,
    wrap: Boolean,
    printMargin: {
      type: [Boolean, Number],
      default: !0
    },
    minLines: Number,
    maxLines: Number
  },
  emits: ["update:value", "init", ...i],
  render() {
    return a("div");
  },
  mounted() {
    const e = this._editor = n(
      o.edit(this.$el, {
        placeholder: this.placeholder,
        readOnly: this.readonly,
        value: this.value,
        mode: "ace/mode/" + this.lang,
        theme: "ace/theme/" + this.theme,
        wrap: this.wrap,
        printMargin: this.printMargin,
        useWorker: !1,
        minLines: this.minLines,
        maxLines: this.maxLines,
        ...this.options
      })
    );
    this._contentBackup = this.value, this._isSettingContent = !1, e.on("change", () => {
      if (this._isSettingContent)
        return;
      const t = e.getValue();
      this._contentBackup = t, this.$emit("update:value", t);
    }), i.forEach((t) => {
      const s = "on" + l(t);
      typeof this.$.vnode.props[s] == "function" && e.on(t, this.$emit.bind(this, t));
    }), this._ro = new c(() => {
      e.resize();
    }), this._ro.observe(this.$el), this.$emit("init", e);
  },
  beforeUnmount() {
    this._ro && this._ro.disconnect(), this._editor && this._editor.destroy();
  },
  methods: {
    focus() {
      this._editor.focus();
    },
    blur() {
      this._editor.blur();
    },
    selectAll() {
      this._editor.selectAll();
    },
    resize(e) {
      this._editor.resize(e);
    }
  },
  watch: {
    value(e) {
      if (this._contentBackup !== e) {
        try {
          this._isSettingContent = !0, this._editor.setValue(e, 1);
        } finally {
          this._isSettingContent = !1;
        }
        this._contentBackup = e;
      }
    },
    theme(e) {
      this._editor.setTheme("ace/theme/" + e);
    },
    options: {
      handler(e) {
        this._editor.setOptions(e);
      },
      deep: !0
    },
    readonly(e) {
      this._editor.setReadOnly(e);
    },
    placeholder(e) {
      this._editor.setOption("placeholder", e);
    },
    wrap(e) {
      this._editor.setWrapBehavioursEnabled(e);
    },
    printMargin(e) {
      this._editor.setOption("printMargin", e);
    },
    lang(e) {
      this._editor.setOption("mode", "ace/mode/" + e);
    },
    minLines(e) {
      this._editor.setOption("minLines", e);
    },
    maxLines(e) {
      this._editor.setOption("maxLines", e);
    }
  }
});
export {
  _ as ModeList,
  g as ThemeList,
  f as VAceEditor
};
