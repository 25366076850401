import request from '../request'
import type { AxiosPromise } from 'axios'
import { md5 } from '@/utils'

export interface ILoginParams {
  username: string
  password: string
}

export interface IPhoneCodeLoginParams {
  contact: string
  code: string
}

export interface IRegisterParams extends ILoginParams, IPhoneCodeLoginParams {}

export interface IGroup {
  id: number
  name: string
  description: string
  file_count: number
  instance_cpu: number
  instance_memory: number
  task_timeout: number
  price: number
  api_rate: number
}
export interface IUser {
  id: number
  username: string
  wechat: boolean
  qq: boolean
  contact: string
  group_end?: string
  avatar?: string
  balance: number
  create_time: string
  group: IGroup
}
export interface IRegisterResponse {
  token: string
  user: IUser
}

export function register (
  data: IRegisterParams
): AxiosPromise<IRegisterResponse> {
  return request('user/register', {
    method: 'POST',
    data: {
      ...data,
      password: md5(data.password)
    }
  })
}

export function login (data: ILoginParams): AxiosPromise<IRegisterResponse> {
  return request('user/login', {
    method: 'POST',
    data: {
      ...data,
      password: md5(data.password)
    }
  })
}

export function loginByCode (
  data: IPhoneCodeLoginParams
): AxiosPromise<IRegisterResponse> {
  return request('user/login/contact', {
    method: 'POST',
    data
  })
}


export interface ICheckContactParams {
  check_type: 'register' | 'retrieve' | 'login' | 'cancellation' | 'bind'
  contact: string
}

export function checkContact (data: ICheckContactParams) {
  return request(`user/contact/check/${data.check_type}`, {
    method: 'POST',
    data: {
      contact: data.contact
    }
  })
}

export interface IResetPasswordParams {
  password: string
  contact: string
  code: string
}

export function resetPassword (data: IResetPasswordParams) {
  return request('user/password', {
    method: 'POST',
    data: {
      ...data,
      password: md5(data.password)
    }
  })
}
