import request from './request/index'
import type { AxiosPromise } from 'axios'

export interface IFile {
  id?: number
  name: string
  runtime: {
    id: number
    language: string
    version: string
  }
  create_time?: string
  update_time?: string
  file?: string
  is_default?: boolean
}
export interface IRunCodeParams {
  file?: string
  name?: string
  runtime?: number
}

export function runCode(
  data: IRunCodeParams
): AxiosPromise<{ connect_id: string,search_token:string }> {
  return request('complier', {
    method: 'POST',
    data
  })
}

// 获取运行时列表
export function getRuntimes() {
  return request('complier/runtimes', {
    method: 'GET'
  })
}

export interface ISetIdeConfig {
  font_size: number
  result_height: number
  theme_style: string
  line_number: boolean
  highlight_active_line: boolean
  code_folding: boolean
  automatic_completion: boolean
  auto_indent: boolean
  show_gutter: boolean
}

// 查询IDE配置信息
export function getIdeConfig(): AxiosPromise<ISetIdeConfig> {
  return request('ide/config', {
    method: 'GET'
  })
}

// 配置IDE配置信息
export function setIdeConfig(data: ISetIdeConfig) {
  return request('ide/config', {
    method: 'POST',
    data
  })
}

export interface IFileCommandParams {
  invoke_command: string
  debug_command: string
}
// 查询针对某文件的IDE配置
export function getFileCommand(
  fileId: string | number | 'anonymous' = 'anonymous'
): AxiosPromise<IFileCommandParams> {
  return request('ide/config/file/' + fileId, {
    method: 'GET'
  })
}

// 配置针对某文件的IDE配置
export function setFileCommand(
  fileId: string | number,
  data: IFileCommandParams
) {
  return request('ide/config/file/' + fileId, {
    method: 'POST',
    data
  })
}

// 获取运行时详情
export function getRuntimeDetail(id: string | number): AxiosPromise<{
  ace_js: string
  debug_command: string
  example: string
  id: number
  invoke_command: string
  language: string
  version: string
}> {
  return request('complier/runtime/' + id, {
    method: 'GET'
  })
}

export interface ICreateFileParams {
  name: string
  runtime: string | number
  file: string
  id?: number
}

// 创建文件
export function createFile(data: ICreateFileParams) {
  return request('file', {
    method: 'POST',
    data
  })
}

// check文件接口
export function checkFile(
  data: Pick<ICreateFileParams, 'name' | 'runtime'>
): AxiosPromise<{
  example: string
  status: string
}> {
  return request('file/check', {
    method: 'POST',
    data
  })
}
// 获取文件列表
export function getFileList() {
  return request('files', {
    method: 'GET'
  })
}

// 获取文件详情
export function getFileDetail(id: string | number) {
  return request('file/' + id, {
    method: 'GET'
  })
}

// 删除文件
export function deleteFile(id: string | number) {
  return request('file/' + id, {
    method: 'DELETE'
  })
}

// 更新指定文件
export function updateFile(
  id: string | number,
  data: {
    file: string
    runtime: string
    name: string
    file_id?: string | number
  }
) {
  return request('file/' + id, {
    method: 'POST',
    data
  })
}

// 下载文件
export function downloadFile(id: string | number) {
  return request('file/' + id + '/download', {
    method: 'GET',
    responseType: 'blob'
  })
}
/* 获取trace */
export function getTrace(searchToken:string){
  return request('stack/ui?search_token=' + searchToken, {
    method: 'GET'
  })
}

