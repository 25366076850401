import request from '../request'
import type { AxiosPromise } from 'axios'
import type { IRegisterResponse } from './account'
/**
 * 生成微信小程序链接
 * @returns
 */
export function getWechatLink(): AxiosPromise<{
  'check-token': string
  url: string
}> {
  return request('user/wechat/link', {
    method: 'GET'
  })
}

/**
 * 客户端校验微信是否登陆成功
 * @param checkToken
 * @returns
 */
export function checkWechatLogin(checkToken: string): AxiosPromise<
  {
    'check-wechat-token'?: string,
    'status'?:string
  } & Partial<IRegisterResponse>
> {
  return request('user/wechat/login/check', {
    method: 'GET',
    headers: {
      'check-token': checkToken
    }
  })
}

/* 微信号登陆时候获取token */
export const getwechattoken = (data:any) => {
  const url = 'user/login/wechat'
  return request(url, {
    method: 'POST',
    data
  })
}

/* 获取微信登陆二维码 */
export const getwechatqrcode = () => {
  const url = 'user/wechat/qrcode'
  return request(url, {
    method: 'GET'
  })
}

export const getqqqrcode = () => {
  const url = 'user/qq/link'
  return request(url, {
    method: 'GET'
  })
}