import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/src/message.scss'
import '@/assets/main.scss'
import init from '@/plugins/init'
import router from './router'
import store from './store'
import { createPinia } from 'pinia'

const device = navigator.userAgent.toLocaleLowerCase()
if (device.indexOf('ipad') > -1 || device.indexOf('mobile') > -1 || device.indexOf('android') > -1 || device.indexOf('iphone') > -1 || device.indexOf('ipod') > -1 || device.indexOf('phone') > -1) {
  window.location.href = 'https://m.anycodes.cn/waysdownload'
}
else{
  const pinia = createPinia()
  const app = createApp(App)
  init()
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  app.use(pinia).use(store).use(router).mount('#app')
  localStorage.removeItem('anycodes-file-content')
}


