import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/index.vue'),
    children:[
      {
      path : '',
      name :'homepage',
      alias: '/home',
      component:()=>import('@/views/HomeView.vue')
    },{
      path : '/bind',
      name :'bind',
      alias: '/home',
      component:()=>import('@/views/HomeView.vue')
    },
    {
      path : '/editor',
      name :'editor',
      component:()=>import('@/views/EditorView.vue')
    },
    {
      path : '/help',
      name :'help',
      component:()=>import('@/views/HelpView.vue')
    },
    {
      path : '/message',
      name :'message',
      component:()=>import('@/views/HelpView.vue')
    },
    {
      path : '/mobile',
      name :'mobile',
      component:()=>import('@/views/PhoneView.vue')
    },
    {
      path : '/modify',
      name :'modifyinfo',
      component:()=>import('@/views/PersonInfo/ModifyInfo.vue')
    }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const forEachRoutes = (
  routes: Array<RouteRecordRaw>,
  fn: (route: RouteRecordRaw, depth: number) => void,
  depth = 0
) => {
  routes.forEach((route) => {
    fn(route, depth)
    if (Array.isArray(route.children) && route.children.length) {
      forEachRoutes(route.children, fn, depth + 1)
    }
  })
}

forEachRoutes(routes, (route, depth) => {
  if (route.meta) {
    route.meta.depth = depth
  } else {
    route.meta = {
      depth
    }
  }
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
