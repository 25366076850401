import type { Module } from 'vuex'
import type { RootState } from './index'

export interface WebsocketState {
  socket: WebSocket | null
}
const websocket: Module<WebsocketState, RootState> = {
  namespaced: true,
  state: () => {
    // const socket = new WebSocket('ws://localhost:8082/api/v1/terminal/0')
    // socket.addEventListener('open', function () {
    //   socket.send('Hello Server!')
    // })

    // // Listen for messages
    // socket.addEventListener('message', function (event) {
    //   console.log('Message from server ', event.data)
    // })
    return {
      socket: null
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
}

export default websocket
