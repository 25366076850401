import request from './request'
// import type { AxiosPromise } from 'axios'
// import { md5 } from '@/utils'

export * from './user/wechat'
export * from './user/qq'
export * from './user/account'

export function getGroups() {
  return request('user/groups')
}

export function getUser() {
  return request('user')
}
