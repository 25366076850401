import { Store, MutationPayload } from 'vuex'
import merge from 'deepmerge'
import set from 'lodash/set'
import get from 'lodash/get'

const noop = () => undefined
interface Storage {
  getItem: (key: string) => any
  setItem: (key: string, value: any) => void
  removeItem: (key: string) => void
}

interface Options<State> {
  key?: string
  paths?: string[]
  reducer?: (state: State, paths?: string[]) => object
  subscriber?: (
    store: Store<State>
  ) => (handler: (mutation: any, state: State) => void) => void
  storage?: Storage
  getState?: (key: string, storage: Storage) => any
  setState?: (key: string, state: any, storage: Storage) => void
  filter?: (mutation: MutationPayload) => boolean
  arrayMerger?: (state: any[], saved: any[]) => any
  rehydrated?: (store: Store<State>) => void
  fetchBeforeUse?: boolean
  overwrite?: boolean
  assertStorage?: (storage: Storage) => void | Error
}

export default function <State> (
  opt?: Options<State>
): (store: Store<State>) => void {
  const options: Options<State> = opt || {}

  const storage = options.storage || (window && window.localStorage)
  const key = options.key || 'vuex'

  function getState (key: string, storage: Storage) {
    const value = storage.getItem(key)

    try {
      return typeof value === 'string'
        ? JSON.parse(value)
        : typeof value === 'object'
          ? value
          : undefined
    } catch (err) {}

    return undefined
  }

  function filter () {
    return true
  }

  function setState (key: string, state: any, storage: Storage) {
    return storage.setItem(key, JSON.stringify(state))
  }

  function reducer (state: any, paths?: string[]) {
    return Array.isArray(paths)
      ? paths.reduce(function (substate, path) {
        return set(substate, path, get(state, path))
      }, {})
      : state
  }

  function subscriber (store: Store<State>) {
    return function (
      handler: (mutation: MutationPayload, state: State) => any
    ) {
      return store.subscribe(handler)
    }
  }

  const assertStorage =
    options.assertStorage ||
    (() => {
      storage.setItem('@@', '1')
      storage.removeItem('@@')
    })

  assertStorage(storage)

  const fetchSavedState = () => (options.getState || getState)(key, storage)

  let savedState: any

  if (options.fetchBeforeUse) {
    savedState = fetchSavedState()
  }

  return function (store: Store<State>) {
    if (!options.fetchBeforeUse) {
      savedState = fetchSavedState()
    }

    if (typeof savedState === 'object' && savedState !== null) {
      store.replaceState(
        options.overwrite
          ? savedState
          : merge(store.state, savedState, {
            arrayMerge:
                options.arrayMerger ||
                function (store, saved) {
                  return saved
                },
            clone: false
          })
      )
      ;(options.rehydrated || noop)(store)
    }

    (options.subscriber || subscriber)(store)(function (mutation, state) {
      if ((options.filter || filter)(mutation)) {
        (options.setState || setState)(
          key,
          (options.reducer || reducer)(state, options.paths),
          storage
        )
      }
    })
  }
}
