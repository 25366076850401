import { ElMessageBox } from 'element-plus'
import { inject } from 'vue'
const toLogin:any = inject('allowSetLogin')
export function confirmLogined() {
  try {
    ElMessageBox.confirm(
      '您还没有登录，点击确定跳转到登录页面',
      'Warning',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).then(() => {
      toLogin(true)
    })
    
  } catch (error) {
  }
}
